












































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { DutyShiftEntityModel, DutyShiftQueryModel } from '@common-src/entity-model/duty-shift-entity';
import DutyShiftService from '@common-src/service/duty-shift';

@Component
export default class DutyShiftListComponent extends TableDialogFormComponent<DutyShiftEntityModel, DutyShiftQueryModel> {
    DutyShiftEntityModel = DutyShiftEntityModel;
    created() {
        this.initTable({
            service: DutyShiftService,
            queryModel: new DutyShiftQueryModel(),
            tableColumns: DutyShiftEntityModel.getTableColumns()
        });
        this.getList();
    }
}

